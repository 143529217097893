.app {
  text-align: center;
  overflow-x: hidden;
  box-sizing: border-box;
}
.header {
  background-color: #000000;
  height: 80px;
}
.main {
  display: grid;
  grid-template-columns: 240px 1fr;
}
.content {
  min-height: calc(100vh - 250px);
  width: 100%;
}
.login-wrapper {
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  background-color: #000000;
  height: 100px;
}
